import React, { useEffect, useRef, useState } from "react";
import { VirtuosoGrid, VirtuosoHandle } from "react-virtuoso";
import styled from "@emotion/styled";
//eslint-disable @typescript-eslint/no-explicit-any
// core components
import { DeviceCardManager } from "components/CustomDeviceCard/CustomDeviceCard";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme
} from "@mui/material";

// local imports
import styles from "./devices.module.scss";
import { Filter, Refresh, Search } from "@mui/icons-material";
import { useAppDispatch } from "apps-middleware/redux/store/hooks";
import { WarningText } from "components/WarningText/WarningText";
import {
  DropdownChecklist,
  Option
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { fetchPlantsThunk } from "apps-middleware/redux/asyncThunks";
import { IMinifiedPlant, IPlant, SystemStatus } from "apps-middleware/types/plant";
import Loading from "components/Loading/Loading";
import { IPlantThunkResponse } from "apps-middleware/types/redux";
import { systemStatusAsArray } from "apps-middleware/constants/plant";
import { systemStatusToSemanticName } from "apps-middleware/util/plant";
import SimpleDenseDropdown from "components/Dropdowns/SimpleDenseDropdown";
import useIsMounted from "apps-middleware/hooks/useIsMounted";
import DeviceTableManager from "components/CustomDeviceCard/DeviceTableManager";

type DeviceLayoutOptions = "card" | "table";

const DEFAULT_CARD_WIDTH = 295;
const DEFAULT_CARD_HEIGHT = 125;

const PAGE_SIZE_OPTIONS = [
  10, 20, 50, 100, 150, 200, 400, 1000, 1500
];

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_SEARCH = "";

const ItemContainer = styled.div`
  padding: 5px;
  width:${DEFAULT_CARD_WIDTH}px;
  height:${DEFAULT_CARD_HEIGHT}px;
  @media (max-width: 500px) {
    width:100%;
  }
`;

// Don't look at what is going on here. Just really don't look at this please

const ListContainer: any = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DEFAULT_IS_VIP = false;

type Feature =
"Optimum" | "Energy Trading" | "Blockchain" | "Smart EV Charger" | "Load Control" | "Power Ranger";
const features: Array<Feature> = [
  "Optimum", "Energy Trading", "Blockchain", "Smart EV Charger", "Load Control", "Power Ranger"
];
const featureFilterOptions: Array<Option> = features.map((f) => ({
  name: f as Feature,
  value: f as Feature
}));

const DEFAULT_FILTER_FEATURE_STATE: Array<Feature> = [];

const systemStatusFilterOptions: Array<Option> = systemStatusAsArray.map((status) => ({
  "name": systemStatusToSemanticName[status],
  "value": status
}));
const DEFAULT_SYSTEM_STATUS_FILTER_STATE: Array<SystemStatus> = [];

function DeviceLayout({layout}:{layout: DeviceLayoutOptions}): JSX.Element {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMounted = useIsMounted();

  const [
    featureFilter,
    setFeatureFilter
  ] = useState<Array<Feature>>(DEFAULT_FILTER_FEATURE_STATE);
  const [
    systemStatusFilter,
    setSystemStatusFilter
  ] = useState<Array<SystemStatus>>(DEFAULT_SYSTEM_STATUS_FILTER_STATE);
  const [onlyVip, setOnlyVip] = useState(DEFAULT_IS_VIP);
  const virtuoso = React.useRef<VirtuosoHandle | null>(null);

  const [isFetching, setIsFetching] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>(DEFAULT_SEARCH);
  const [activeSearchValue, setActiveSearchValue] = useState<string>(DEFAULT_SEARCH);
  const submitNewSearch = () => setActiveSearchValue(searchInputValue);

  const [loadedPlants, setLoadedPlants] = useState<(IPlant | IMinifiedPlant)[]>([]);
  const [totalAvailable, setTotalAvailable] = useState(Infinity);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const initialRender = useRef(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const thunk = useRef<any>();

  const [currentPage, setCurrentPage] = useState(1);
  const [forceRefresh, setForceRfresh] = useState([]);

  const totalPageCount = Math.ceil(totalAvailable / pageSize);

  function onPageSizeChange(newVal: string) {
    reset();
    const newPageSize = parseInt(newVal);
    setPageSize(Number.isNaN(newPageSize) ? DEFAULT_PAGE_SIZE : newPageSize);
  }

  function resetFilter() {
    setSearchInputValue(DEFAULT_SEARCH);
    setActiveSearchValue(DEFAULT_SEARCH);

    setSystemStatusFilter(DEFAULT_SYSTEM_STATUS_FILTER_STATE);
    setFeatureFilter(DEFAULT_FILTER_FEATURE_STATE);
    setOnlyVip(DEFAULT_IS_VIP);
  }

  function reset() {
    setLoadedPlants([]);
    setCurrentPage(1);
    setForceRfresh([]);
  }

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  useEffect(() => {
    if (virtuoso.current) virtuoso.current.scrollToIndex(0);
    runDataFetching();

  }, [currentPage, pageSize, forceRefresh]);

  useEffect(
    //the function to run when dependancy changes
    function onDependanciesChange() {
      if (initialRender.current) {
        // the downside of useEffect is it will always run on the first render.
        // we dont want this to happen on the first render, so we just do a simple
        // on first render check of this effect
        initialRender.current = false;
        return;
      }
      //when dependancies change, start by resetting the loaded plants array as it is now stale
      reset();
    },
    // the dependancies to listen for changes on
    [
      activeSearchValue,
      JSON.stringify(systemStatusFilter),
      onlyVip,
      JSON.stringify(featureFilter)
    ]
  );

  async function runDataFetching() {

    const pagesLoaded = Math.ceil(loadedPlants.length / pageSize);

    if (currentPage <= pagesLoaded) return; //no need to get this page

    setIsFetching(true);
    if (thunk.current) {
      thunk.current.abort();
    }

    thunk.current = dispatch(fetchPlantsThunk({
      page: pagesLoaded + 1,
      limit: pageSize,
      anyFieldQuery: activeSearchValue.replace(/ /g, "%2B"),
      status: systemStatusFilter[0],
      isOptimus: featureFilter.includes("Optimum") ? true : undefined,
      tradingAllowed: featureFilter.includes("Energy Trading") ? true : undefined,
      cryptoAllowed: featureFilter.includes("Blockchain") ? true : undefined,
      hasSmartEvCharger: featureFilter.includes("Smart EV Charger") ? true : undefined,
      hasLoadControl: featureFilter.includes("Load Control") ? true : undefined,
      hasPowerRanger: featureFilter.includes("Power Ranger") ? true : undefined,
      isVip: onlyVip ? true : undefined,
    }));

    thunk.current
      ?.unwrap()
      .then((response: IPlantThunkResponse) => {
        if (isMounted()) {
          setLoadedPlants((array) => ([
            ...array,
            ...response.data.plants
          ]));

          setTotalAvailable(
            response.pagination
              ? response.pagination.totalAvailable : response.data.plants.length
          );
        }
      })
      .catch(() => {
        // abort silently
      })
      .finally(() => {
        if (isMounted()) {
          setIsFetching(false);
          thunk.current = undefined;
        }
      });
  }

  function handleSearchValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchInputValue(event.target.value);
  }

  let bodyContent: null | JSX.Element = null;

  if (loadedPlants.length === 0) {
    <WarningText
      message="No devices matching that search."
      showNetworkWarning
    />;
  } else {
    const layoutToContentType: Record<DeviceLayoutOptions, JSX.Element> = {
      table: <DeviceTableManager plants={loadedPlants || []} />,
      card: <VirtuosoGrid
        ref={virtuoso}
        overscan={{
          main: 20,
          reverse: 10,
        }}
        style={{ height: "100%" }}
        components={{
          Item: ItemContainer,
          List: ListContainer,
        }}
        totalCount={totalPageCount === currentPage
          ? totalAvailable - ((currentPage - 1) * pageSize) : pageSize}
        itemContent={(index) => {
          const trueIndex = index + ((currentPage - 1) * pageSize);
          return (
            <DeviceCardManager
              plantId={loadedPlants[trueIndex] ? loadedPlants[trueIndex].plantId : undefined}
            />
          );
        }
        }
      />
    };
    bodyContent = layoutToContentType[layout];
  }

  const filtersAreInDefaultState = (
    activeSearchValue === "" &&
    JSON.stringify(systemStatusFilter) === JSON.stringify(DEFAULT_SYSTEM_STATUS_FILTER_STATE) &&
    !onlyVip &&
    JSON.stringify(featureFilter) === JSON.stringify(DEFAULT_FILTER_FEATURE_STATE)
  );

  const LayoverContainer = ({
    children
  }: {
    children: JSX.Element
  }) => (
    <div style={{
      position: "absolute", top: 0, left: 0,
      width: "100%",
      height: "100%",
      zIndex: 100,
      display: "grid",
      placeContent: "center",
      placeItems: "center"
    }}>{children}</div>
  );

  return (
    <div className={styles.container}>
      <div style={{
        display: "flex",
        gap: "1em",
        alignItems: "center"
      }}>
        <Box component="form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          e.stopPropagation();
          submitNewSearch();
        }}>
          <TextField
            label="Search Devices"
            variant="outlined"
            size="small"
            value={searchInputValue}
            onInputCapture={handleSearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={submitNewSearch}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              )
            }} />
        </Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
          }}
          onClick={() => setOnlyVip(!onlyVip)}>
          <Checkbox checked={onlyVip} />
          <Typography variant="button">
            Only Show VIP
          </Typography>
        </div>
        <DropdownChecklist
          popUpSize={{
            width: window.innerWidth * 0.25,
            height: window.innerHeight * 0.7,
          }}
          defaultSelected={DEFAULT_FILTER_FEATURE_STATE}
          controlledSelected={featureFilter.map((f) => ({ "name": f, "value": f }))}
          items={featureFilterOptions}
          onSelectionChange={(items) => {
            setFeatureFilter(items.map((o) => o.value as Feature));
          }}
          labelText={"Filter Features"}
          icon={<Filter />} />
        <DropdownChecklist
          isRadio
          popUpSize={{
            width: window.innerWidth * 0.25,
            height: window.innerHeight * 0.65,
          }}
          defaultSelected={DEFAULT_SYSTEM_STATUS_FILTER_STATE}
          items={systemStatusFilterOptions}
          controlledSelected={
            systemStatusFilter[0] !== undefined
              ? [{
                "name": systemStatusToSemanticName[systemStatusFilter[0]],
                "value": systemStatusFilter[0]
              }] : []
          }
          onSelectionChange={(items: Option[]) => {
            setSystemStatusFilter(items.map((o) => o.value as SystemStatus));
          }}
          labelText={"Filter Status"}
          icon={<Filter />} />
        <div style={{
          flex: 1,
          display: "flex",
          justifyContent: "end"
        }}>
          <Button
            variant="outlined"
            disabled={filtersAreInDefaultState}
            startIcon={<Refresh />}
            onClick={resetFilter}>
            Reset Filters
          </Button>

        </div>
      </div>

      <div style={{ display: "block", marginTop: 20, position: "relative" }}>
        {
          isFetching && <LayoverContainer>
            <Loading informationText={"Fetching Page"} />
          </LayoverContainer>
        }
        {
          !isFetching &&
          loadedPlants.length === 0 &&
          !filtersAreInDefaultState &&
          <LayoverContainer>
            <>
              <Typography style={{
                color: theme.palette.primary.contrastText
              }}>No plants matching these filters.</Typography>
              <div style={{ height: 10 }}></div>
              <Button
                variant="outlined"
                disabled={filtersAreInDefaultState}
                startIcon={<Refresh />}
                onClick={resetFilter}>
                Reset Filters
              </Button>
            </>
          </LayoverContainer>
        }
        {bodyContent}
      </div>

      <div style={{
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        padding: "10px 0px 0px 0px",
        alignItems: "center",
      }}>
        <div style={{
          display: "flex",
          alignItems: "center"
        }}>
          <SimpleDenseDropdown
            onChange={onPageSizeChange}
            options={PAGE_SIZE_OPTIONS.map((o) => String(o))}
            label={`Page Size: ${pageSize}`}
          />
        </div>
        <div style={{
          display: "flex",
          gap: 10,
          alignItems: "center"
        }}>
          <Button
            variant="outlined"
            disabled={currentPage === 1 || isFetching}
            onClick={previousPage}>
            PREVIOUS
          </Button>
          <Typography >
            Page {currentPage} of {totalPageCount === Infinity ? "---" : totalPageCount}
          </Typography>
          <Button
            variant="outlined"
            disabled={currentPage >= totalPageCount || isFetching}
            onClick={nextPage}>
            NEXT
          </Button>
        </div>
        <div style={{
          display: "grid",
          justifyContent: "end"
        }}>
          <Typography>
            Total Available: {totalAvailable === Infinity ? "---" : totalAvailable}
          </Typography>
        </div>
      </div>
    </div >
  );
}

export default DeviceLayout;
