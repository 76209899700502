import React, { useState, useEffect } from "react";
import DeviceLayout from "./DeviceLayout";
import HomeTop from "views/Home/homeTop";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function Devices(): JSX.Element {
  // Retrieve initial state from localStorage or default to false
  const [showDeviceCard, setShowDeviceCard] = useState(() => {
    const storedState = localStorage.getItem("showDeviceCard");
    return storedState ? JSON.parse(storedState) : false;
  });

  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    // Check if tooltip has been dismissed previously
    const tooltipDismissed = localStorage.getItem("tooltipDismissed");
    if (tooltipDismissed) {
      setShowTooltip(false);
    }
  }, []);

  // Function to toggle the card view
  const toggleCard = () => {
    const newState = !showDeviceCard;
    setShowDeviceCard(newState);
    localStorage.setItem("showDeviceCard", JSON.stringify(newState)); // Store state in localStorage
    setShowTooltip(false); // Hide tooltip when user interacts with the toggle
    localStorage.setItem("tooltipDismissed", "true"); // Persist dismissal in localStorage
  };

  return (
    <div style={{ width: "100%"}}>
      <HomeTop />
      <Box
        sx={{
          position: "relative",
          left: 20,
          paddingBottom: 2,
          zIndex: 1000, // Ensure it's above other content
          display: "flex",
          alignItems: "center",
          width: 200
        }}
      >
        <FormControlLabel
          control={
            <Tooltip
              title={
                <React.Fragment>
                  <span>Prefer the old look?</span>
                  <IconButton
                    size="small"
                    aria-label="close"
                    onClick={() => setShowTooltip(false)}
                    sx={{ ml: 1 }}
                    style={{ zIndex: "1200"}}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
              arrow
              open={showTooltip}
              onClose={() => setShowTooltip(false)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
            >
              <Switch checked={showDeviceCard} onChange={toggleCard} />
            </Tooltip>
          }
          label={showDeviceCard ? "Card View" : "List View"}
          labelPlacement="start"
          sx={{ marginLeft: "10px", marginTop: "-8px" }} // Adjust margin to align with content
        />
      </Box>
      <Box style={{ marginTop: "-20px", textAlign: "center" }}>
        <DeviceLayout layout={showDeviceCard ? "card" : "table"}/>
      </Box>
    </div>
  );
}

export default Devices;
