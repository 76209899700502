import { request } from ".";
import {
  CachedDataIntervals,
  IAggregatePlantRequestParams,
  IAggregatePlantResponse,
  IConnectCustomerToPlantResponse,
  ICumulativeDailyFinancialDataResponse,
  ICumulativeEnvironmentalDataResponse,
  ICumultiveDailyDataParams,
  ILinkByQRParams,
  IListUserPlantsResponse,
  IPlantGetDataV2Params,
  IPlantGetDataV2Response,
  IWeatherResponse,
  RequestResult,
  RequestResultPerCode,
  ServerResponse,
  V3PlantsListQueryparams,
} from "../types/server";
import { HouseMode, IPlant, PlantId, TariffType, WarrantyPutParams } from "../types/plant";
import { TradingAlgorithms } from "../types/trading";

export type DateBounds = [string, string]

export function fetchUserPlants(
  query: V3PlantsListQueryparams,
): RequestResult<IListUserPlantsResponse> {

  return request(
    "v3/plants",
    "GET",
    undefined,
    undefined,
    query
  );
}

export function fetchPlantInfo(plantId: string): RequestResult<IPlant> {
  return request(`v3/plants/${plantId}`, "GET");
}

export function fetchPlantData(params: IPlantGetDataV2Params):
  RequestResult<IPlantGetDataV2Response> {
  return request("v2/plant/getData", "POST", params);
}

export function fetchWeather(
  plantId: string
): RequestResult<
IWeatherResponse
> {
  return request(
    `v3/plants/${plantId}/weather`,
    "GET"
  );
}

export function fetchFinancialData(
  plantId: string,
  interval: CachedDataIntervals,
  queryParams?: {
    startDate?: string,
    endDate?: string,
    year?: string
  }
): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["data"]["financial"]["{interval}"]["GET"]["200"]
> {
  return request(
    `v3/plants/${plantId}/data/financial/${interval}`,
    "GET",
    undefined,
    undefined,
    queryParams
  );
}

export function fetchCumulativeFinancialData(
  params: ICumultiveDailyDataParams
): RequestResult<ICumulativeDailyFinancialDataResponse> {
  return request("v2/plant/getCumulativeFinancialData", "POST", params);
}

export function fetchEnvironmentalData(
  plantId: string,
  interval: CachedDataIntervals,
  queryParams: {
    startDate?: string,
    endDate?: string,
    year?: string
  }
): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["data"]["environmental"]["{interval}"]["GET"]["200"]
> {
  return request(
    `v3/plants/${plantId}/data/environmental/${interval}`,
    "GET",
    undefined,
    undefined,
    queryParams
  );
}

export function fetchCumulativeEnvironmentalData(
  params: ICumultiveDailyDataParams
): RequestResult<ICumulativeEnvironmentalDataResponse> {
  return request("v2/plant/getCumulativeEnvironmentalData", "POST", params);
}

export async function putPlantTariff(
  plantId: string,
  body: {
    tariffType: TariffType,
    state?: string,
    single?: number | null,
    peak?: number | null,
    shoulder?: number | null,
    offpeak?: number | null,
    export?: number | null,
    amberKey?: string | null,
  }
): RequestResult<ServerResponse["v3"]["plants"]["{plantId}"]["tariff"]["PUT"]["200"]> {
  return request(
    `v3/plants/${plantId}/tariff`,
    "PUT",
    body
  );
}

export function confirmPossiblePlant(
  plantId: string, email: string
): RequestResult<IConnectCustomerToPlantResponse> {
  return request("v1/plant/connectCustomerToPlant", "POST", { email, plantId });
}

export function fetchAggregatePlantData(params: IAggregatePlantRequestParams):
  RequestResult<IAggregatePlantResponse> {
  return request("v2/plant/getAggregateData", "POST", params);
}

export function linkPlantByQRCode(params: ILinkByQRParams): RequestResult<null> {
  return request("v2/plant/linkByQrId", "POST", params);
}

export function sendLinkRequest(bodyParams: {
  firstName?: string,
  surname?: string,
  resellerName?: string,
  directPurchase: boolean,
  serialNumber: string,
}): RequestResult<null> {
  return request(
    "v2/user/sendLinkRequest",
    "POST",
    bodyParams
  );
}

export function fetchTradingHistory(params: {
  plantId: string,
  startTimestamp: number,
  endTimestamp: number,
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["trading"]["history"]["200"]> {
  return request(
    "v3/plants/" + params.plantId + "/trading/history/" +
    "?startTimestamp=" + params.startTimestamp +
    "&endTimestamp=" + params.endTimestamp,
    "GET"
  );
}

export function fetchTradingStatus(params: {
  plantId: PlantId
}): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["trading"]["status"]["200"]> {
  return request(
    `v3/plants/${params.plantId}/trading/status`,
    "GET",
  );
}

/** Whitelisted properties that a basic user can update on `/v3/plants/{plantId}` */
export function putPlant(
  plantId: string,
  params: {
    houseMode?: HouseMode,
    timezone?: string,
    tradingEnabled?: boolean,
    forceGridStatus?: boolean,
    tradingAlgorithmType?: TradingAlgorithms,
    importPriceSetPoint?: number,
    exportPriceSetPoint?: number,
  }
): RequestResult<ServerResponse["v3"]["plants"]["{plantId}"]["PUT"]["204"]> {
  return request(
    `v3/plants/${plantId}`,
    "PUT",
    params
  );
}

export function putPlantWarranty(
  qrId: string,
  params: WarrantyPutParams
): RequestResult<ServerResponse["v3"]["warranties"]["{qrId}"]["PUT"]["204"]> {
  return request(
    `v3/warranties/${qrId}`,
    "PUT",
    params
  );
}

export function getPlantWarranty(
  qrId: string
):
  // TODO: this doesn't work. Figure out a clean way to type multiple response bodies to codes
  // and have those returns types be detected by functions which call the get and ask for a
  // specific code. e.g. response.code == 200 then response.data is typed to the 200 response result
  RequestResultPerCode<ServerResponse["v3"]["warranties"]["{qrId}"]["GET"]["204"], 204> |
  RequestResultPerCode<ServerResponse["v3"]["warranties"]["{qrId}"]["GET"]["200"], 200> |
  RequestResult<unknown> {
  return request(
    `v3/warranties/${qrId}`,
    "GET"
  );
}

export function putView(
  viewId: PlantId,
  params: {
    name?:string,
    displayName?:string
  }
): RequestResult<ServerResponse["v3"]["views"]["{viewId}"]["PUT"]["200"]> {
  return request(`v3/views/${viewId}`, "PUT", params);
}