/**
 * Is this string empty? Empty means no meaningful characters like
 * letters, symbols and numbers. Any string that contains only
 * white space like space/enters is empty.
 *
 * @param str possible string
 * @return boolean if string is empty
 */
export function isEmptyString(str: string): boolean {
  const whiteSpaceRemoved = str.replaceAll(/\s/g, "");
  return whiteSpaceRemoved === "";
}