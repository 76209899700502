import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { usePlant } from "apps-middleware/hooks/usePlant";
import { PlantId } from "apps-middleware/types/plant";

export default function DeviceDataTables({ plantId }: { plantId?: PlantId }) {
  const plant = usePlant({ plantId, getFullInfoASAP: false });

  const renderTableSection = (
    sectionTitle: string,
    dataPairs: [string, any][]
  ): JSX.Element => {
    return (
      <TableContainer
        component={Paper}
        elevation={3}
        style={{ marginTop: "1rem" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" gutterBottom>
                  {sectionTitle}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPairs.map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <strong>{key}</strong>
                </TableCell>
                <TableCell>
                  {Array.isArray(value) ? ( // FIX THIS FOR MPPTS
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Barcode</TableCell>
                          <TableCell>Battery Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.map((battery, index) => (
                          <TableRow key={index}>
                            <TableCell>{battery.barcode}</TableCell>
                            <TableCell>{battery.batteryType}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : typeof value === "object" ? (
                    JSON.stringify(value, null, 2)
                  ) : (
                    value
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box margin={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {renderTableSection("General Plant Information", [
            ["Physical Plant ID", plant?.physicalPlantId],
            ["Plant ID", plant?.plantId],
            ["Plant Name", plant?.plantName],
            ["Plant View Name", plant?.plantViewName],
            ["Chassis Id", plant?.chassisId],
            ["Timezone", plant?.timezone],
            ["QR ID", plant?.qrId],
            ["Plant Provider", plant?.plantProviderName],
            ["Batteries", plant?.batteries],
            ["Warranty Name", plant?.warrantyName],
          ])}
        </Grid>

        <Grid item xs={12} md={6}>
          {renderTableSection("Pricing / Trading Information", [
            ["Trading Allowed", plant?.tradingAllowed ? "Yes" : "No"],
            ["Trading Enabled", plant?.tradingEnabled ? "Yes" : "No"],
            ["Trading Forbidden", plant?.tradingForbidden ? "Yes" : "No"],
            ["Tariff Type", plant?.tariffType],
            ["Pricing Configured", plant?.isPricingConfigured ? "Yes" : "No"],
          ])}
        </Grid>

        <Grid item xs={12} md={6}>
          {renderTableSection("Plant Status", [
            ["Online", plant?.online ? "Yes" : "No"],
            ["Alerts", plant?.alerts?.length ?? 0],
          ])}
        </Grid>

        <Grid item xs={12} md={6}>
          {renderTableSection("PPP Subscriptions", [
            ["Is VIP", plant?.isVip ? "Yes" : "No"],
            ["Has Optimum", plant?.isOptimus ? "Yes" : "No"],
            ["Has Load Control", plant?.hasLoadControl ? "Yes" : "No"],
            ["Has Smart EV Charger", plant?.hasSmartEvCharger ? "Yes" : "No"],
            ["Has Power Ranger", plant?.hasPowerRanger ? "Yes" : "No"],
          ])}
        </Grid>
      </Grid>
    </Box>
  );
}
