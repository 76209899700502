import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Button,
} from "@mui/material";

import { KeyboardBackspace } from "@mui/icons-material";
import { useAppSelector } from "apps-middleware/redux/store/hooks";
import { selectCurrentPlantId } from "apps-middleware/redux/selectors/plants";
import { usePlant } from "apps-middleware/hooks/usePlant";
import { useNavigate } from "react-router-dom";
import "./energyStyles.css";
import SimDetailsPage from "./SimDetailsPage";
import EnergyTradingPage from "./EnergyTradingPage";
import PlantConfigurationPage from "./PlantConfigurationPage";
import FinancialImpactPage from "./FinancialImpactPage";
import DashboardHomePage from "./DashboardHomePage";
import GraphPage from "./GraphPage";
import DeviceDataTables from "components/CustomDeviceCard/DeviceDataTables";

enum EnergyPageTabs {
  EnergyDashboard = "Energy Dashboard",
  HistoricalData = "Historical Data",
  FinancialImpact = "Financial Impact",
  PlantConfiguration = "Plant Configuration",
  PlantDataTables = "Plant Information",
  RedearthPPP = "RedEarth PPP",
  SimDetails = "Sim Details",
}

function Energy() {
  const [selectedMenu, setSelectedMenu] = useState<EnergyPageTabs>(
    EnergyPageTabs.EnergyDashboard
  );

  const handleMenuClick = (item: EnergyPageTabs) => {
    setSelectedMenu(item);
  };
  const currentPlantId = useAppSelector(selectCurrentPlantId);

  const plant = usePlant({ plantId: currentPlantId });

  const simId = plant?.configurationDetails
    ? (plant.configurationDetails as { sim_imei?: string }).sim_imei // eslint-disable-next-line indent
        ?.replaceAll(" ", "")
    : undefined;

  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <div className="energy-root">
      <CssBaseline />

      {/* Sidebar Menu */}
      <Drawer
        className="energy-drawer"
        variant="permanent"
        classes={{
          paper: "energy-drawerPaper",
        }}
      >
        <List>
          {(Object.values(EnergyPageTabs) as EnergyPageTabs[]).map(
            (tabName) => {
              const tabButton = (
                <ListItemButton
                  selected={selectedMenu === tabName}
                  onClick={() => handleMenuClick(tabName)}
                >
                  <ListItemText primary={tabName} />
                </ListItemButton>
              );

              if (tabName === EnergyPageTabs.RedearthPPP) {
                if (plant?.tradingAllowed || plant?.hasPowerRanger) {
                  return tabButton;
                }
              } else if (tabName === EnergyPageTabs.SimDetails) {
                if (simId !== undefined) {
                  return tabButton;
                }
              } else return tabButton;
            }
          )}
        </List>
      </Drawer>

      {/* Main Content */}
      <div className="energy-content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {window.history.state && window.history.state.idx > 0 ? (
            <Button
              onClick={goBack}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<KeyboardBackspace />}
            >
              Return to system list
            </Button>
          ) : null}
          <Typography variant="h5" className="energy-plant-name">
            {plant?.plantViewName ?? plant?.plantName ?? "--"}
          </Typography>
        </div>
        <Grid>
          {selectedMenu === EnergyPageTabs.HistoricalData && (
            <Grid item xs={12}>
              <Card>
                <GraphPage />
              </Card>
            </Grid>
          )}

          {selectedMenu === EnergyPageTabs.EnergyDashboard && (
            <Grid item xs={12}>
              <DashboardHomePage />
            </Grid>
          )}

          {selectedMenu === EnergyPageTabs.FinancialImpact && (
            <Grid item xs={12} style={{ width: "100%" }}>
              <FinancialImpactPage currentPlantId={currentPlantId} />
            </Grid>
          )}

          {selectedMenu === EnergyPageTabs.PlantConfiguration && (
            <Grid item xs={12}>
              <PlantConfigurationPage />
            </Grid>
          )}

          {selectedMenu === EnergyPageTabs.PlantDataTables && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <DeviceDataTables plantId={plant?.plantId} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          {selectedMenu === EnergyPageTabs.RedearthPPP && (
            <Grid item xs={12} style={{ width: "100%" }}>
              <Card>
                <EnergyTradingPage />
              </Card>
            </Grid>
          )}

          {selectedMenu === EnergyPageTabs.SimDetails && (
            <Grid item xs={12}>
              <SimDetailsPage simId={simId} />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default Energy;
