import React, { useEffect, useState } from "react";
import SimpleConfirm from "./SimpleConfirm";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import { isEmptyString } from "apps-middleware/util/string";

export default function RenamePlantModal({
  open,
  close,
  rename,
}: {
  open: boolean;
  close: () => void;
  rename: (nextName: string) => Promise<void>;
}) {
  const [confirmMessage, setConfirmMessage] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [nextName, setNextName] = useState("");

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();
      setIsFetching(true);
      const cleanedName = nextName.trim();
      if (typeof cleanedName !== "string") {
        throw Error("Input value is not string.");
      }
      if(isEmptyString(cleanedName)) {
        throw Error("Name cannot be an empty string");
      }
      await rename(nextName);
      close();
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "Unable to update plant.";
      setConfirmMessage(message);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(function onOpen() {
    if(open) {
      setNextName("");
    }
  }, [open]);

  const invalidInput = isEmptyString(nextName);

  return (
    <React.Fragment>
      <SimpleConfirm
        open={confirmMessage !== null}
        close={() => setConfirmMessage(null)}
        title="Unable to update plant"
        message={confirmMessage ?? ""}
      />
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={close}
        PaperProps={{
          component: "form",
          onSubmit,
        }}
      >
        <DialogTitle>Rename Plant View</DialogTitle>
        <DialogContent>
          <TextField
            value={nextName}
            onChange={(e) => setNextName(e.currentTarget.value)}
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="New name"
            fullWidth
            variant="standard"
            error={invalidInput && nextName !== ""}
          />
        </DialogContent>
        {isFetching && <CircularProgress style={{placeSelf:"center"}}/>}
        <DialogActions>
          <Button onClick={close} disabled={isFetching}>Cancel</Button>
          <Button type="submit" disabled={isFetching || invalidInput}>Rename Plant</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
