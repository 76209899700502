import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Button,
  Stack,
  Snackbar,
} from "@mui/material";
import Loading from "components/Loading/Loading";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IPlant, IMinifiedPlant, PlantId } from "apps-middleware/types/plant";
import { Link } from "react-router-dom";
import "./DeviceTableManagerStyles.css"; // Import external stylesheet
import { usePlant } from "apps-middleware/hooks/usePlant";
import RenamePlantModal from "components/Modals/RenamePlant";
import { Close } from "@mui/icons-material";
import DeviceDataTables from "./DeviceDataTables";

interface Props {
  plants?: (IPlant | IMinifiedPlant)[];
}

const isIPlant = (plant: IPlant | IMinifiedPlant): plant is IPlant =>
  "plantId" in plant;

const formatSOC = (soc?: number): string => {
  if (soc !== undefined) {
    return soc.toFixed(0) + "%";
  }
  return "-";
};

const CollapsibleRow: React.FC<{ plantId: PlantId }> = ({ plantId }) => {
  const plant = usePlant({
    plantId: plantId,
    getFullInfoASAP: false,
  });
  const [open, setOpen] = React.useState(false);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(
    null
  );

  const toggleCollapse = () => setOpen(!open);

  async function renamePlant(nextName: string) {
    if (plant === undefined) throw Error("No plant defined.");
    await plant?.setName.setPlantDisplayName(nextName);
    setSnackbarMessage("Plant name changed successfully");
  }

  return (
    <>
      <RenamePlantModal
        open={showRenameModal}
        close={() => setShowRenameModal(false)}
        rename={renamePlant}
      />
      <Snackbar
        message={snackbarMessage}
        open={snackbarMessage !== null}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage(null)}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => setSnackbarMessage(null)}
          >
            <Close />
          </IconButton>
        }
      />
      <TableRow key={plant?.plantId}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleCollapse}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {plant?.plantViewName ?? plant?.plantName}
        </TableCell>
        <TableCell>{plant?.status ?? "-"}</TableCell>
        <TableCell>
          {formatSOC(plant?.currentPlantData?.batterySoc ?? undefined)}
        </TableCell>
        <TableCell>{plant?.online ? "Yes" : "No"}</TableCell>
        <TableCell>{plant?.alerts?.length ?? 0}</TableCell>
        <TableCell>
          <Stack direction={"row"} gap={1}>
            <Button
              component={Link}
              to={`/device/${plant?.plantId}`}
              variant="outlined"
              size="small"
              color="primary"
            >
              Select Plant
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowRenameModal(true)}
              size="small"
              color="primary"
            >
              Rename
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <DeviceDataTables plantId={plantId} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const DeviceTableManager: React.FC<Props> = ({ plants }) => {
  if (!plants) {
    return (
      <div style={{ marginTop: "100px" }}>
        <Loading size={150} />
      </div>
    );
  }

  return (
    <Paper style={{ marginTop: "-20px" }}>
      <TableContainer className="tableContainer">
        <Table stickyHeader>
          <TableHead>
            <TableRow className="stickyHeader">
              <TableCell>Plant Name</TableCell>
              <TableCell>Plant Status</TableCell>
              <TableCell>Battery SOC</TableCell>
              <TableCell>Online</TableCell>
              <TableCell>Alerts</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plants
              .filter((plant) => isIPlant(plant))
              .map(({ plantId }) => (
                <CollapsibleRow key={plantId} plantId={plantId} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DeviceTableManager;
